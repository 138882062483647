<template>
    <div class="pageMain">
      <div v-if="infoShow">
      <search-head :model="searchForm" @search="search" @reset="reset"  :isExport="true">
        <el-form :inline="true" :model="searchForm">
        <el-form-item label="公司名称">
          <el-input v-model="searchForm.compName" size="small" placeholder="公司名称"></el-input>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-input v-model="searchForm.projName" size="small" placeholder="项目名称"></el-input>
        </el-form-item>
      
      </el-form>
      </search-head>
      <custom-tables :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChanges="handleCurrentChanges"
          height="55vh"
          :total="total"
          
          title="设备统计信息">
        <!-- <template slot="btns">
          <el-button type="primary" size="small" @click="addFrom">新建</el-button>
        </template> -->
        <el-table-column type="selection" align="center" width="55">
      </el-table-column>
        <el-table-column
            prop="projName"
            label="项目名称"
            align="center"
          >
        </el-table-column>
        <el-table-column
            prop="compName"
            align="center"
            label="公司名称"
          >
        </el-table-column>
        <el-table-column prop="deviceType" label="设备类型" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.deviceType == 0">塔吊</span>
              <span v-if="scope.row.deviceType == 1">升降机</span>
              <span v-if="scope.row.deviceType == 2">龙门吊</span>
              <span v-if="scope.row.deviceType == 3">架桥机</span>
              <span v-if="scope.row.deviceType == 4">爬模</span>
              <span v-if="scope.row.deviceType == 5">挂篮</span>
              <span v-if="scope.row.deviceType == 8">扬尘</span>
          <span v-if="scope.row.deviceType == 9">喷淋</span>
        </template>
        </el-table-column>
        <el-table-column
            prop="devicecount"
            align="center"
            label="设备总数"
          >
        </el-table-column>
       
        <el-table-column
            prop="deviceOnlineCount"
            align="center"
            label="设备在线数"
          >
          <template slot-scope="scope">
             <span style="color: #4f9aee;">{{ scope.row.deviceOnlineCount }}</span>
        </template>
        </el-table-column>
<!--        
        <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >查看</span>
            </template>
          </el-table-column> -->
      </custom-tables>
     
    </div>
    <!-- <monitorAlarm v-if="editShow" :compId="compId"></monitorAlarm> -->
    </div>
  </template>
  
  <script>
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import CustomDialog from "../../../components/common/CustomDialog";
// import monitorAlarm from '../../../components/monitor/monitorAlarm.vue'
import monitor from "../../../api/modules/monitor";
import {equipType,useState} from "../../../config/dataStatus"
  export default {
    name: "index",
    components: {CustomDialog, CustomTables, SearchHead,},
    data() {
      return {
        equipTypeList:equipType,
        tableData: [
         
        ],
        searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
        total:0,
        showDialog: false,
        headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      editShow:false,
      infoShow:true,
      compId:'',
      orgaId:'',
      type:''
      }
    },
    mounted() {
        const item = this.$route.query;
        console.log('item',item);
        this.type = item.item
        this.orgaId=item.orgaId
      this.getList()
    },
    methods:{
      getList() {
        this.searchForm.orgaId=this.orgaId
        this.searchForm.deviceType= this.type
      monitor.getPageCount(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getList();
    },
      addFrom(){
        this.showDialog = true
      },
      close(){
        this.showDialog = false
      },
      submit(){
        this.showDialog = false
      },
      detailItem(row){
        //详情
        this.editShow = true
        this.infoShow = false
        this.compId=row.compId
        
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.pageNum = pageIndex;
      this.getList();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      this.getList();
    },
    handleCurrentChanges(row){
      console.log('row',row);
      this.$router.push({
       path: `/view/monitor/deviceTotalProject`,
       query: {
        projId: row.projId,
        deviceType: row.deviceType,
    }
     });
      
    },
    handleSelectionChange(val){
        console.log(val);
        
    }
  
    }
  }
  </script>
  
  <style scoped lang="less">

  </style>